import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_commercial.module.scss"

const SpecifiedCommercialTransactionsPage = () => (
  <Layout>
    <Seo title="特定商取引法に基づく表記" />

    <div className={styles.pageWrapper}>
      <h1>特定商取引法に基づく表記</h1>

      <table>
        <thead>
          <tr>
            <th>販売店名</th>
            <td>リンクチャネル株式会社長岡CSセンター</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>販売店所在地</th>
            <td>新潟県長岡市東蔵王2-6-20 吉沢ビル2F</td>
          </tr>
          <tr>
            <th>販売店電話番号</th>
            <td>0258-25-6320 (営業時間：弊社休業日、祭日を除く月曜-金曜の10:00-17:00)</td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td>shop@renk.co.jp</td>
          </tr>
          <tr>
            <th>販売URL</th>
            <td>https://www.renk.co.jp/online-store</td>
          </tr>
          <tr>
            <th>販売業者名</th>
            <td>リンクチャネル株式会社</td>
          </tr>
          <tr>
            <th>運営統括責任者</th>
            <td>入倉 孝行</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>新潟県新発田市新栄町3-1-13</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>0254-24-4344</td>
          </tr>
          <tr>
            <th>お支払い方法</th>
            <td>銀行振込</td>
          </tr>
          <tr>
            <th>商品代金以外の必要金額</th>
            <td>
              送料：商品価格には商品の送料が含まれています。(日本国内のみ)<br />
              振込手数料：お客様負担です。<br />
            </td>
          </tr>
          <tr>
            <th>販売数量</th>
            <td>1個から</td>
          </tr>
          <tr>
            <th>お申込み有効期限</th>
            <td>ご注文後７日以内と致します。</td>
          </tr>
          <tr>
            <th>商品引渡し時期</th>
            <td>
              前払いの場合、指定日が無ければ入金確認後10営業日以内で発送致します。<br />
              後払いの場合、商品到着後7日以内にご入金ください。<br />
              なお、機材の入荷状況により前後する場合もございます。その際は別途お知らせ致します。
            </td>
          </tr>
          <tr>
            <th>配送方法・送料について</th>
            <td>
              全品・全国ヤマト運輸にてお届け致します。<br />
              送料は商品代金に含まれております。
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th rowSpan="3">返品・不良品について</th>
            <td>
              「不良品・当社の商品の間違い」の場合は当社が負担致します。<br />
              配送途中の破損などの事故がございましたら、弊社までご連絡下さい。<br />
              送料・手数料ともに弊社負担で早急に新品をご送付致します。
            </td>
          </tr>
          <tr>
            <td>
            【返品対象】<br />
            「不良品・当社の商品の間違い」の場合
            </td>
          </tr>
          <tr>
            <td>
            【返品時期】<br />
            ご購入後7日以内にご連絡があった場合に返金可能となります。
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </Layout>
)

export default SpecifiedCommercialTransactionsPage
